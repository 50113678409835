import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const ContentSeparator = styled.div`
  width: 100%;
  height: 78px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  border-radius: 0 15px;
  border: 1px solid #e6e6e6;
  text-align: center;

  span {
    font-weight: bold;
    font-size: 26px;
    font-family: 'Neue Haas Grotesk Bloomberg';
    color: #19406d;

    @media ${device.s} {
      font-size: 18px;
      margin: 5px;
    }
  }
`
