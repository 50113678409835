import React from 'react'
import { graphql } from 'gatsby'
import queryString from 'query-string'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import USAElectionContent from '../components/usaElection'
import SearchGrid from '../components/search/grid/searchGrid'

const USAElectionPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const seoTitle = 'أحدث أخبار الانتخابات الأميركية 2020 | الشرق للأخبار'
  const seoDescription =
    'أحدث الأخبار فيما يتعلق بانتخابات الرئاسة الأمريكية لعام 2020 ومستجدات حملات المرشحين، بالإضافة إلى تبعات المنافسة ما بين ترامب وبايدن عبر الشرق للأخبار'

  return (
    <>
      <Banner page="us-election" />
      <Layout location={location} title={siteTitle} lang={'ar'}>
        <SEO title={seoTitle} lang="ar" description={seoDescription} />
        <USAElectionContent />
        <SearchGrid
          mode="search"
          searchCategory={
            location.search ? queryString.parse(location.search).category : null
          }
          ignoreLatestNews
        />
      </Layout>
    </>
  )
}

export default USAElectionPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
