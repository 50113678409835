import React, { useEffect } from 'react'
import Pym from 'pym.js'

import { ContentSeparator } from './index.styled'

const USAElectionContent = () => {
  const isClient = typeof window === 'object'
  const SHOW_EMBEDDED_URL = process.env.GATSBY_SHOW_EMBEDDED_URL || false
  const EMBEDDED_CONTENT = process.env.GATSBY_EMBEDDED_URL || ''

  const contents = EMBEDDED_CONTENT !== '' ? JSON.parse(EMBEDDED_CONTENT) : null

  useEffect(() => {
    if (
      isClient &&
      SHOW_EMBEDDED_URL &&
      SHOW_EMBEDDED_URL === 'true' &&
      contents &&
      contents.length > 0
    ) {
      contents.map((content, index) => {
        new Pym.Parent(`iframe-${index}`, content.url.replace(/\s/g, ''), {})
      })
    }
  }, [])

  if (!SHOW_EMBEDDED_URL || SHOW_EMBEDDED_URL === 'false' || !contents) {
    return null
  }

  return (
    <div
      style={{
        marginTop: '-20px'
      }}
    >
      <div
        style={{
          backgroundColor: 'white'
        }}
      >
        {contents.map((content, index) => {
          if (content && content.url) {
            return (
              <div
                key={index}
                style={{
                  margin: '30px 0',
                  paddingTop: '30px',
                  backgroundColor: 'white'
                }}
              >
                <div id={`iframe-${index}`}></div>
              </div>
            )
          }
        })}
      </div>
      <ContentSeparator>
        <span>آخر المستجدات حول الانتخابات الأمريكية 2020</span>
      </ContentSeparator>
    </div>
  )
}

export default USAElectionContent
